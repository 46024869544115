import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import VList from 'web/src/components/List/VList/VList.vue';
import VListItemExtended from 'web/src/components/List/VListItemExtended/VListItemExtended.vue';
import { useLiveChatBusyForm } from 'web/src/modules/live-chat/components/LiveChatBusyForm/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LiveChatBusyForm',
    props: {
        isCallbackEnabled: {
            type: Boolean
        }
    },
    emits: [
        "feedback",
        "callback"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        const { emailIcon, phoneIcon, emitFeedback, emitCallback } = useLiveChatBusyForm(emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['busy-form'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['busy-form__title'])
                }, _toDisplayString(_ctx.$t('WEB2_LIVECHAT_BUSY_TITLE')), 3),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['busy-form__description'])
                }, _toDisplayString(_ctx.$t('WEB2_LIVECHAT_BUSY_DESCRIPTION')), 3),
                _createVNode(VList, null, {
                    default: _withCtx(()=>[
                            _ctx.isCallbackEnabled ? (_openBlock(), _createBlock(VListItemExtended, {
                                key: 0,
                                "icon-name": _unref(phoneIcon),
                                title: _ctx.$t('WEB2_CALL_CUSTOMER_SUPPORT'),
                                "sub-title-first": _ctx.$t('WEB2_CALL_CUSTOMER_SUPPORT_DESC'),
                                onClick: _unref(emitCallback)
                            }, null, 8, [
                                "icon-name",
                                "title",
                                "sub-title-first",
                                "onClick"
                            ])) : _createCommentVNode("", true),
                            _createVNode(VListItemExtended, {
                                "icon-name": _unref(emailIcon),
                                title: _ctx.$t('WEB2_JSP_EMAIL_CUSTOMER_SUPPORT'),
                                "sub-title-first": _ctx.$t('WEB2_JSP_EMAIL_CUSTOMER_SUPPORT_DESC'),
                                onClick: _unref(emitFeedback)
                            }, null, 8, [
                                "icon-name",
                                "title",
                                "sub-title-first",
                                "onClick"
                            ])
                        ]),
                    _: 1
                })
            ], 2)), [
                [
                    _directive_auto_id,
                    'LiveChatBusyForm'
                ]
            ]);
        };
    }
});
