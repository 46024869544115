import { computed, onMounted, ref } from 'vue';
import { useIntersectionObserver } from '@leon-hub/vue-utils';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useLiveChatSurveyForm(props, emits) {
    const { $translate } = useI18n();
    const wrapper = ref();
    const maxLength = 1000;
    const comment = ref('');
    const selectedRating = ref(0);
    const isRatingChangeAvailable = ref(true);
    const isSendButtonDisabled = computed(()=>(props.processing ?? !comment.value) || !selectedRating.value);
    const isCommentChangeAvailable = computed(()=>!props.surveyMessage);
    const submitButton = computed(()=>({
            disabled: isSendButtonDisabled.value,
            kind: ButtonKind.TRANSPARENT_BLUE,
            label: $translate('LIVECHAT_SURVEY_SUBMIT_BTN').value,
            fullWidth: true
        }));
    onMounted(()=>{
        if (props.surveyRating) isRatingChangeAvailable.value = false;
        if (props.surveyMessage) comment.value = props.surveyMessage;
    });
    function onChangeComment(event) {
        comment.value = event.target.value;
    }
    function submitRatingSurvey(rating) {
        if (rating) {
            selectedRating.value = rating;
            emits('survey-submit', {
                rating,
                comment: null
            });
            isRatingChangeAvailable.value = false;
        }
    }
    useIntersectionObserver(wrapper, (isIntersecting)=>{
        if (!isIntersecting && selectedRating.value > 0) isRatingChangeAvailable.value = false;
    });
    function submitFullSurvey() {
        if (comment.value) {
            emits('survey-submit-full', {
                rating: selectedRating.value,
                comment: comment.value
            });
            isRatingChangeAvailable.value = false;
        }
    }
    return {
        wrapper,
        maxLength,
        comment,
        onChangeComment,
        submitButton,
        submitRatingSurvey,
        submitFullSurvey,
        isCommentChangeAvailable,
        isRatingChangeAvailable
    };
}
