import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 1
};
const _hoisted_2 = {
    key: 2
};
import { VIcon } from '@components/v-icon';
import { useLiveChatAction } from 'web/src/modules/live-chat/components/LiveChatAction/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LiveChatAction',
    props: {
        action: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { iconName, isLike, isDislike, showIcon, iconProps, onClick } = useLiveChatAction(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['chat-action']]: true,
                    [_ctx.$style['chat-action--with-title']]: !!_ctx.action.title
                }),
                onClick: _cache[0] || (_cache[0] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(onClick) && _unref(onClick)(...args);
                })
            }, [
                _unref(showIcon) ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['chat-action__icon'])
                }, [
                    _unref(iconName) ? (_openBlock(), _createBlock(_unref(VIcon), _normalizeProps(_mergeProps({
                        key: 0
                    }, _unref(iconProps))), null, 16)) : _createCommentVNode("", true),
                    _unref(isLike) ? (_openBlock(), _createElementBlock("span", _hoisted_1, "👍")) : _createCommentVNode("", true),
                    _unref(isDislike) ? (_openBlock(), _createElementBlock("span", _hoisted_2, "👎")) : _createCommentVNode("", true)
                ], 2)) : _createCommentVNode("", true),
                _createElementVNode("span", null, _toDisplayString(_ctx.action.title), 1)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LiveChatAction'
                ]
            ]);
        };
    }
});
