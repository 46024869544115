import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { LiveChatActionType } from 'web/src/modules/live-chat/enums';
export default function useLiveChatAction(props, emit) {
    const iconName = computed(()=>{
        if (props.action.type === LiveChatActionType.OPERATOR) return IconName.CHAT_PROCESSING;
    });
    const isLike = computed(()=>props.action.type === LiveChatActionType.LIKE);
    const isDislike = computed(()=>props.action.type === LiveChatActionType.DISLIKE);
    const showIcon = computed(()=>!!iconName.value || isLike.value || isDislike.value);
    const iconProps = computed(()=>({
            name: iconName.value,
            size: IconSize.SIZE_20
        }));
    function onClick() {
        emit('click', props.action.payload);
    }
    return {
        iconName,
        isLike,
        isDislike,
        showIcon,
        iconProps,
        onClick
    };
}
