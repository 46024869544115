import { computed, ref } from 'vue';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { LiveChatMode } from 'web/src/modules/live-chat/enums';
export default function useLiveChatBody(props, emits) {
    const scrollbar = ref();
    const isBusyMode = computed(()=>props.mode === LiveChatMode.busy);
    const isConversationMode = computed(()=>props.mode === LiveChatMode.opened || props.mode === LiveChatMode.started || props.mode === LiveChatMode.preopened);
    async function scrollDown() {
        await nextAnimationFrame();
        scrollbar.value?.scrollDown();
    }
    function emitFeedback() {
        emits('feedback');
    }
    function emitCallback() {
        emits('callback');
    }
    function emitPreviewFile(input) {
        emits('preview-file', input);
    }
    function emitSurveyRatingSubmit(data) {
        emits('survey-submit', data);
    }
    function emitSurveyFullSubmit(data) {
        emits('survey-submit-full', data);
    }
    function emitOpenFaq() {
        emits('open-faq');
    }
    function emitDoAction(payload) {
        emits('do-action', payload);
    }
    return {
        scrollbar,
        isBusyMode,
        isConversationMode,
        scrollDown,
        emitFeedback,
        emitCallback,
        emitPreviewFile,
        emitSurveyRatingSubmit,
        emitSurveyFullSubmit,
        emitOpenFaq,
        emitDoAction
    };
}
