import { IconName } from '@leon-hub/icons';
export default function useLiveChatBusyForm(emits) {
    const emailIcon = IconName.MAIL;
    const phoneIcon = IconName.PHONE;
    function emitFeedback() {
        emits('feedback', true);
    }
    function emitCallback() {
        emits('callback', true);
    }
    return {
        emailIcon,
        phoneIcon,
        emitFeedback,
        emitCallback
    };
}
