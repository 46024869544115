import { computed, onMounted, ref, watch } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import FileUtils from 'web/src/utils/FileUtils';
import { getCurrentImage, getHeaderProperties } from 'web/src/modules/live-chat/components/LiveChatImagesPreview/composables/utils';
export default function useLiveChatImagesPreview(props, emits) {
    const contentSwiper = ref();
    const navigationSwiper = ref();
    const headerProperties = computed(()=>getHeaderProperties(props));
    const currentImage = computed(()=>getCurrentImage(props));
    onMounted(()=>{
        contentSwiper.value?.slideToSlide(props.activeIndex);
        navigationSwiper.value?.slideToSlide(props.activeIndex, {
            isCentered: true
        });
    });
    watch(()=>props.activeIndex, ()=>{
        onActiveIndexChanged();
    });
    function onActiveIndexChanged() {
        if (contentSwiper.value?.getActiveSlideIndex() !== props.activeIndex) contentSwiper.value?.slideToSlide(props.activeIndex, {
            smooth: true
        });
        navigationSwiper.value?.slideToSlide(props.activeIndex, {
            smooth: true,
            speed: 300,
            isCentered: true
        });
    }
    function getFileIcon(fileName) {
        const extension = FileUtils.extension(fileName);
        return {
            name: 'PDF' === extension.toUpperCase() ? IconName.FILE_PDF : IconName.FILE_DOWNLOAD,
            size: IconSize.SIZE_100
        };
    }
    function onSlideClick(value) {
        if (props.activeIndex !== value) emitSlideChange(value);
    }
    function onScroll(data) {
        emitSlideChange(data.activeSlide);
    }
    function downloadImage() {
        const image = currentImage.value;
        if (image) FileUtils.download(image);
    }
    function emitSlideChange(value) {
        emits('slide-change', value);
    }
    function emitHide() {
        emits('hide', true);
    }
    function emitDragStart(event) {
        emits('drag-start', event);
    }
    return {
        contentSwiper,
        navigationSwiper,
        headerProperties,
        emitDragStart,
        emitHide,
        downloadImage,
        onScroll,
        getFileIcon,
        onSlideClick
    };
}
