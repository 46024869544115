import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { LiveChatMessageType } from 'web/src/modules/live-chat/services/api/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LiveChatMessageTime',
    props: {
        time: {},
        type: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['message-time']]: true,
                    [_ctx.$style['message-time--client']]: _ctx.type === _unref(LiveChatMessageType).client
                })
            }, [
                _createTextVNode(_toDisplayString(_ctx.time), 1)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LiveChatMessageTime'
                ]
            ]);
        };
    }
});
