import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import { getCloseIconName, getHeaderProperties, getSuffixIconName } from 'web/src/modules/live-chat/components/LiveChatHeader/composables/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
import MediaUtils from 'web/src/utils/MediaUtils';
import { LiveChatMode } from 'web/src/modules/live-chat/enums';
export default function useLiveChatHeader(props, emits) {
    const { $translate } = useI18n();
    const volumeIconName = computed(()=>props.muted ? IconName.VOLUME_OFF : IconName.VOLUME_ON);
    const showSoundButton = computed(()=>props.mode !== LiveChatMode.busy);
    const iconName = computed(()=>getCloseIconName(props));
    // eslint-disable-next-line max-len
    const suffixIconName = computed(()=>getSuffixIconName(props, showSoundButton.value, volumeIconName.value));
    const title = computed(()=>$translate('LIVECHAT_HEADER_NAME').value);
    const headerProperties = computed(()=>getHeaderProperties(iconName.value, suffixIconName.value, title.value));
    function onSuffixClick() {
        if (showSoundButton.value) emitChangeMuted();
    }
    function emitChangeMuted() {
        if (props.muted) MediaUtils.playSound('message.mp3');
        emits('change-muted', !props.muted);
    }
    function emitPrefixClick() {
        emits('prefix-click');
    }
    function emitDragStart(event) {
        emits('drag-start', event);
    }
    return {
        headerProperties,
        onSuffixClick,
        emitPrefixClick,
        emitDragStart
    };
}
