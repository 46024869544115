import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { LiveChatMode } from 'web/src/modules/live-chat/enums';
import { useLiveChatHeader } from 'web/src/modules/live-chat/components/LiveChatHeader/composables';
import ModalWindowHeader from 'web/src/components/Modal/ModalWindowHeader/ModalWindowHeader.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LiveChatHeader',
    props: {
        mode: {
            default: LiveChatMode.busy
        },
        muted: {
            type: Boolean
        },
        loaded: {
            type: Boolean
        },
        chatStatus: {
            default: 3
        }
    },
    emits: [
        "email",
        "prefix-click",
        "change-muted",
        "drag-start"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { headerProperties, onSuffixClick, emitPrefixClick, emitDragStart } = useLiveChatHeader(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ModalWindowHeader, _mergeProps(_unref(headerProperties), {
                onPrefixClick: _unref(emitPrefixClick),
                onSuffixClick: _unref(onSuffixClick),
                onDragStart: _unref(emitDragStart)
            }), null, 16, [
                "onPrefixClick",
                "onSuffixClick",
                "onDragStart"
            ])), [
                [
                    _directive_auto_id,
                    'LiveChatHeader'
                ]
            ]);
        };
    }
});
