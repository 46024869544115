import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import FileUtils from 'web/src/utils/FileUtils';
import { getFormattedFileName } from 'web/src/modules/live-chat/components/LiveChatFilePreview/composables/utils';
export default function useLiveChatFilePreview(props, emits) {
    const trashButton = {
        iconName: IconName.BIN,
        iconSize: IconSize.SIZE_16,
        kind: ButtonKind.BASE,
        rounded: true,
        height: ButtonHeight.TINY
    };
    const isImageLayout = computed(()=>FileUtils.isImage(props.file.url));
    const fileIcon = computed(()=>{
        const extension = FileUtils.extension(props.file.name);
        return {
            name: 'PDF' === extension.toUpperCase() ? IconName.FILE_PDF : IconName.FILE_DOWNLOAD,
            size: IconSize.SIZE_28
        };
    });
    const formattedFileName = computed(()=>getFormattedFileName(props));
    function emitClick() {
        emits('click', true);
    }
    function emitDelete() {
        emits('delete', true);
    }
    return {
        isImageLayout,
        fileIcon,
        formattedFileName,
        trashButton,
        emitClick,
        emitDelete
    };
}
