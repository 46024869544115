import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src",
    "alt"
];
import { VIcon } from '@components/v-icon';
import LiveChatFilePreview from 'web/src/modules/live-chat/components/LiveChatFilePreview/LiveChatFilePreview.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import ModalWindowHeader from 'web/src/components/Modal/ModalWindowHeader/ModalWindowHeader.vue';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import { useLiveChatImagesPreview } from 'web/src/modules/live-chat/components/LiveChatImagesPreview/composables';
import FileUtils from 'web/src/utils/FileUtils';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LiveChatImagesPreview',
    props: {
        allowDelete: {
            type: Boolean
        },
        title: {},
        images: {},
        activeIndex: {
            default: -1
        }
    },
    emits: [
        "slide-change",
        "hide",
        "remove",
        "drag-start"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { contentSwiper, navigationSwiper, headerProperties, emitDragStart, emitHide, downloadImage, onScroll, getFileIcon, onSlideClick } = useLiveChatImagesPreview(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['images'])
            }, [
                _createVNode(ModalWindowHeader, _mergeProps(_unref(headerProperties), {
                    onDragStart: _unref(emitDragStart),
                    onPrefixClick: _unref(emitHide),
                    onSuffixClick: _unref(downloadImage)
                }), null, 16, [
                    "onDragStart",
                    "onPrefixClick",
                    "onSuffixClick"
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['images-content'])
                }, [
                    _createVNode(_unref(VSwiper), {
                        ref_key: "contentSwiper",
                        ref: contentSwiper,
                        "is-block": "",
                        "is-full-height": "",
                        "is-scroll-snap-enabled": "",
                        class: _normalizeClass(_ctx.$style['images__swiper']),
                        onScroll: _unref(onScroll)
                    }, {
                        default: _withCtx(()=>[
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index)=>(_openBlock(), _createBlock(VSwiperSlide, {
                                        key: '1sl' + index,
                                        class: _normalizeClass(_ctx.$style['images__swiper-slide'])
                                    }, {
                                        default: _withCtx(()=>[
                                                _unref(FileUtils).isImage(image.url) ? (_openBlock(), _createElementBlock("img", {
                                                    key: 0,
                                                    class: _normalizeClass(_ctx.$style['images-content__image']),
                                                    src: image.url,
                                                    alt: image.name
                                                }, null, 10, _hoisted_1)) : (_openBlock(), _createElementBlock("div", {
                                                    key: 1,
                                                    class: _normalizeClass(_ctx.$style['images__file'])
                                                }, [
                                                    _createElementVNode("div", {
                                                        class: _normalizeClass(_ctx.$style['images__file-inner']),
                                                        onClick: _cache[0] || (_cache[0] = //@ts-ignore
                                                        function() {
                                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                                args[_key] = arguments[_key];
                                                            }
                                                            return _unref(downloadImage) && _unref(downloadImage)(...args);
                                                        })
                                                    }, [
                                                        _createVNode(_unref(VIcon), _mergeProps({
                                                            ref_for: true
                                                        }, _unref(getFileIcon)(image.url), {
                                                            class: _ctx.$style['images__file-icon']
                                                        }), null, 16, [
                                                            "class"
                                                        ]),
                                                        _createElementVNode("div", {
                                                            class: _normalizeClass(_ctx.$style['images__file-name'])
                                                        }, _toDisplayString(image.name), 3)
                                                    ], 2)
                                                ], 2))
                                            ]),
                                        _: 2
                                    }, 1032, [
                                        "class"
                                    ]))), 128))
                            ]),
                        _: 1
                    }, 8, [
                        "class",
                        "onScroll"
                    ])
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['images-footer'])
                }, [
                    _createVNode(_unref(VSwiper), {
                        ref_key: "navigationSwiper",
                        ref: navigationSwiper,
                        "is-scroll-snap-enabled": ""
                    }, {
                        default: _withCtx(()=>[
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index)=>(_openBlock(), _createBlock(VSwiperSlide, {
                                        key: '2nd' + index,
                                        class: _normalizeClass(_ctx.$style['images-footer__swiper-slide'])
                                    }, {
                                        default: _withCtx(()=>[
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['images-footer__swiper-content'])
                                                }, [
                                                    _createVNode(LiveChatFilePreview, {
                                                        file: image,
                                                        class: _normalizeClass({
                                                            [_ctx.$style['images-footer__image']]: true,
                                                            [_ctx.$style['images-footer__image--active']]: _ctx.activeIndex === index
                                                        }),
                                                        "is-active": _ctx.activeIndex === index,
                                                        "is-preview": "",
                                                        onClick: ($event)=>_unref(onSlideClick)(index)
                                                    }, null, 8, [
                                                        "file",
                                                        "class",
                                                        "is-active",
                                                        "onClick"
                                                    ])
                                                ], 2)
                                            ]),
                                        _: 2
                                    }, 1032, [
                                        "class"
                                    ]))), 128))
                            ]),
                        _: 1
                    }, 512)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LiveChatImagesPreview'
                ]
            ]);
        };
    }
});
