import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, mergeProps as _mergeProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { MultilineTextInput } from 'web/src/components/Input';
import LiveChatMessageTime from 'web/src/modules/live-chat/components/LiveChatMessageTime/LiveChatMessageTime.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useLiveChatSurveyForm } from 'web/src/modules/live-chat/components/LiveChatSurveyForm/composables';
import FeedbackRating from 'web/src/modules/feedback/submodules/feedback-rating/components/FeedbackRating/FeedbackRating.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LiveChatSurveyForm',
    props: {
        type: {},
        time: {},
        surveyRating: {},
        surveyMessage: {},
        processing: {
            type: Boolean
        }
    },
    emits: [
        "survey-submit",
        "survey-submit-full"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { maxLength, comment, onChangeComment, submitButton, submitFullSurvey, submitRatingSurvey, isCommentChangeAvailable, wrapper, isRatingChangeAvailable } = useLiveChatSurveyForm(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "wrapper",
                ref: wrapper,
                class: _normalizeClass(_ctx.$style['survey__container'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['survey']]: true
                    })
                }, [
                    _createVNode(FeedbackRating, {
                        "info-icon": {
                            name: _unref(IconName).INFO_OUTLINE,
                            size: _unref(IconSize).SIZE_20
                        },
                        "is-rating-change-available": _unref(isRatingChangeAvailable),
                        "survey-rating": _ctx.surveyRating,
                        title: _ctx.$t('LIVECHAT_TEXT_FINISHED'),
                        "allowed-to-change-rating-text": _ctx.$t('LIVECHAT_TEXT_RATE_OPERATOR'),
                        "disabled-to-change-rating-text": _ctx.$t('LIVECHAT_SURVEY_SUBMIT_LABEL_RATING'),
                        onSubmitRating: _cache[0] || (_cache[0] = ($event)=>_unref(submitRatingSurvey)($event))
                    }, null, 8, [
                        "info-icon",
                        "is-rating-change-available",
                        "survey-rating",
                        "title",
                        "allowed-to-change-rating-text",
                        "disabled-to-change-rating-text"
                    ]),
                    _unref(isCommentChangeAvailable) ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        _createVNode(_unref(MultilineTextInput), {
                            name: "comment",
                            value: _unref(comment),
                            placeholder: `${_ctx.$t('LIVECHAT_SURVEY_SUBMIT_COMMENT')}...`,
                            "max-length": _unref(maxLength),
                            "rows-max": 3,
                            onChange: _unref(onChangeComment),
                            onInput: _unref(onChangeComment)
                        }, null, 8, [
                            "value",
                            "placeholder",
                            "max-length",
                            "onChange",
                            "onInput"
                        ]),
                        _createVNode(VButton, _mergeProps(_unref(submitButton), {
                            class: _ctx.$style['survey__submit'],
                            onClick: _unref(submitFullSurvey)
                        }), null, 16, [
                            "class",
                            "onClick"
                        ])
                    ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                        key: 1
                    }, [
                        _ctx.surveyMessage || _unref(comment) ? (_openBlock(), _createElementBlock("p", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['survey__text'])
                        }, _toDisplayString(_ctx.surveyMessage || _unref(comment)), 3)) : _createCommentVNode("", true)
                    ], 64))
                ], 2),
                _createVNode(LiveChatMessageTime, {
                    time: _ctx.time,
                    type: _ctx.type
                }, null, 8, [
                    "time",
                    "type"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'LiveChatSurveyForm'
                ]
            ]);
        };
    }
});
