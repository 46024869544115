import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { LiveChatMessageType } from 'web/src/modules/live-chat/services/api/enums';
import LiveChatMessageTime from 'web/src/modules/live-chat/components/LiveChatMessageTime/LiveChatMessageTime.vue';
import LiveChatFilePreview from 'web/src/modules/live-chat/components/LiveChatFilePreview/LiveChatFilePreview.vue';
import { useLiveChatFilesMessage } from 'web/src/modules/live-chat/components/LiveChatFilesMessages/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LiveChatFilesMessage',
    props: {
        type: {
            default: LiveChatMessageType.staff
        },
        files: {
            default: ()=>[]
        },
        time: {
            default: ''
        }
    },
    emits: [
        "preview-file"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        const { emitPreviewFile } = useLiveChatFilesMessage(emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['files-message']]: true,
                    [_ctx.$style['files-message--client']]: _ctx.type === _unref(LiveChatMessageType).client
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['files-message__files'])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file)=>(_openBlock(), _createBlock(LiveChatFilePreview, {
                            key: file.url,
                            file: file,
                            type: _ctx.type,
                            class: _normalizeClass(_ctx.$style['files-message__file']),
                            onClick: ($event)=>_unref(emitPreviewFile)(file.url)
                        }, null, 8, [
                            "file",
                            "type",
                            "class",
                            "onClick"
                        ]))), 128))
                ], 2),
                _createVNode(LiveChatMessageTime, {
                    time: _ctx.time,
                    type: _ctx.type,
                    class: _normalizeClass(_ctx.$style['files-message__time'])
                }, null, 8, [
                    "time",
                    "type",
                    "class"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'LiveChatFilesMessage'
                ]
            ]);
        };
    }
});
