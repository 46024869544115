import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src",
    "alt"
];
import { VIcon } from '@components/v-icon';
import { LiveChatMessageType } from 'web/src/modules/live-chat/services/api/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useLiveChatFilePreview } from 'web/src/modules/live-chat/components/LiveChatFilePreview/composables';
import FileUtils from 'web/src/utils/FileUtils';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LiveChatFilePreview',
    props: {
        type: {
            default: LiveChatMessageType.staff
        },
        file: {},
        isFooter: {
            type: Boolean
        },
        isPreview: {
            type: Boolean
        },
        isDeleteAllowed: {
            type: Boolean
        },
        isActive: {
            type: Boolean
        }
    },
    emits: [
        "click",
        "delete"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { isImageLayout, fileIcon, formattedFileName, trashButton, emitClick, emitDelete } = useLiveChatFilePreview(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['chat-file']]: true,
                    [_ctx.$style['chat-file--image']]: _unref(FileUtils).isImage(props.file.url),
                    [_ctx.$style['chat-file--file']]: !_unref(FileUtils).isImage(props.file.url),
                    [_ctx.$style['chat-file--client']]: _ctx.type === _unref(LiveChatMessageType).client,
                    [_ctx.$style['chat-file--footer']]: _ctx.isFooter,
                    [_ctx.$style['chat-file--preview']]: _ctx.isPreview,
                    [_ctx.$style['chat-file--active']]: _ctx.isActive
                })
            }, [
                _unref(isImageLayout) ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['chat-file__image-holder']),
                    onClick: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(emitClick) && _unref(emitClick)(...args);
                    })
                }, [
                    _createElementVNode("img", {
                        class: _normalizeClass(_ctx.$style['chat-file__image']),
                        src: _ctx.file.url,
                        alt: _ctx.file.name
                    }, null, 10, _hoisted_1),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['chat-file__image-hover'])
                    }, null, 2)
                ], 2)) : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['chat-file__file']),
                    onClick: _cache[1] || (_cache[1] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(emitClick) && _unref(emitClick)(...args);
                    })
                }, [
                    _createVNode(_unref(VIcon), _mergeProps(_unref(fileIcon), {
                        class: _ctx.$style['chat-file__icon']
                    }), null, 16, [
                        "class"
                    ]),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['chat-file__name'])
                    }, _toDisplayString(_unref(formattedFileName)), 3)
                ], 2)),
                _ctx.isDeleteAllowed ? (_openBlock(), _createBlock(VButton, _mergeProps({
                    key: 2,
                    class: _ctx.$style['chat-file__delete']
                }, _unref(trashButton), {
                    onClick: _unref(emitDelete)
                }), null, 16, [
                    "class",
                    "onClick"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LiveChatFilePreview'
                ]
            ]);
        };
    }
});
