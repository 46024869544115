import linkify from 'web/src/utils/linkify';
export default function getFormattedText(props) {
    let formattedText = linkify(props.text);
    formattedText = formattedText.replace(/&gt;/gim, '>').replace(/&lt;/gim, '<').replace(/([^\n\r>]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
    return removeExtraLinkTags(formattedText);
}
function removeExtraLinkTags(text) {
    const regex = /<a href=['"]<a href=["'](.*?)["'] target=['"](.*?)['"]>(.*?)<\/a>['"]>(.*?)<\/a>/gi;
    return text.replace(regex, '<a href="$1" target="$2">$4</a>');
}
