let FileUtils = class FileUtils {
    static extension(path) {
        const pathArray = path.split('.');
        return pathArray.length > 1 ? pathArray.pop() || '' : '';
    }
    static download(url) {
        const link = document.createElement('a');
        link.href = url;
        link.download = url;
        link.target = '_blank';
        document.body.append(link);
        link.click();
        link.remove();
    }
    static isImage(url) {
        return /\.(jpeg|jpg|gif|png)$/.test(url);
    }
    static blobToFile(theBlob, fileName) {
        return new File([
            theBlob
        ], fileName || `${Date.now()}.png`, {
            lastModified: Date.now(),
            type: 'image/png'
        });
    }
    static fileNameFromSrc(source) {
        const filename = source.slice(Math.max(0, source.lastIndexOf('/') + 1));
        return filename.split('@')[0];
    }
};
export { FileUtils as default };
