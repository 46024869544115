import { computed } from 'vue';
import { Tag } from '@leon-hub/tags';
import { LiveChatMessageType } from 'web/src/modules/live-chat/services/api/enums';
import { getFormattedText } from 'web/src/modules/live-chat/components/LiveChatMessage/composables/utils';
export default function useLiveChatMessage(props, emits) {
    const spanTag = Tag.SPAN;
    const isSystem = computed(()=>props.type === LiveChatMessageType.system);
    const isBot = computed(()=>props.type === LiveChatMessageType.bot);
    const formattedText = computed(()=>getFormattedText(props));
    function emitClick() {
        emits('click', true);
    }
    function emitOpenFaq() {
        emits('open-faq', true);
    }
    return {
        spanTag,
        formattedText,
        isSystem,
        isBot,
        emitClick,
        emitOpenFaq
    };
}
