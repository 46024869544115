import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { Timer } from '@leon-hub/utils';
import RouteName from '@leon-hub/routing-config-names';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { LiveChatMode, LiveChatStatus } from 'web/src/modules/live-chat/enums';
import { LiveChatMessageType } from 'web/src/modules/live-chat/services/api/enums';
import FileUtils from 'web/src/utils/FileUtils';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useLiveChatStore } from 'web/src/modules/live-chat/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useThemeStore } from 'web/src/modules/theme/store';
import { useTheme } from 'web/src/modules/theme/composables';
export default function useLiveChat(props, emits) {
    const { $translate } = useI18n();
    const { showDialog } = useDialogs();
    const liveChatStore = useLiveChatStore();
    const configStore = useSiteConfigStore();
    const router = useRouter();
    const supportBlock = toRef(configStore, 'supportBlock');
    const loaded = toRef(liveChatStore, 'loaded');
    const mode = toRef(liveChatStore, 'mode');
    const muted = toRef(liveChatStore, 'muted');
    const enabled = toRef(liveChatStore, 'enabled');
    const lastChat = toRef(liveChatStore, 'lastChat');
    const loadingFilesCounter = toRef(liveChatStore, 'loadingFilesCounter');
    const tempFiles = toRef(liveChatStore, 'tempFiles');
    const staffTyping = toRef(liveChatStore, 'staffTyping');
    const currentChat = toRef(liveChatStore, 'currentChat');
    const uploader = ref();
    const footer = ref();
    const body = ref();
    const previewImages = ref([]);
    const allowPreviewImagesDelete = ref(false);
    const currentPreviewImageIndex = ref(0);
    const previewImageTitle = ref('');
    let sendRequestPerforming = false;
    const surveyProcessing = ref(false);
    const message = ref('');
    const startMessageParams = computed(()=>({
            // eslint-disable-next-line max-len
            faqLink: `<span @click="$emit('open-faq')" class="cms-contact__chat--link">${$translate('WEB2_LIVECHAT_START_MESSAGE_FAQ').value}</span>`
        }));
    useThemeStore();
    const { isLight } = useTheme();
    let getInterval = 0;
    const isCallbackEnabled = computed(()=>supportBlock.value?.callbackEnabled);
    const chatMode = computed(()=>loaded.value && !enabled.value ? LiveChatMode.busy : mode.value);
    const isDisabledInput = computed(()=>currentChat.value.isInputDisabled);
    const showFooter = computed(()=>loaded.value && (chatMode.value === LiveChatMode.opened || chatMode.value === LiveChatMode.started || chatMode.value === LiveChatMode.preopened));
    // eslint-disable-next-line max-len,@typescript-eslint/no-unsafe-enum-comparison
    const chatActive = computed(()=>currentChat.value.status === LiveChatStatus.INCOMING || currentChat.value.status === LiveChatStatus.IN_CHAT);
    const showImagesPreviewModal = computed(()=>previewImages.value.length > 0);
    // eslint-disable-next-line max-len
    const activeChat = computed(()=>mode.value === LiveChatMode.preopened && lastChat.value ? lastChat.value : currentChat.value);
    // eslint-disable-next-line max-len
    const startMessage = computed(()=>liveChatStore.startText || $translate('WEB2_LIVECHAT_START_MSG', startMessageParams).value);
    const chatStatus = computed(()=>currentChat.value.status);
    const isSurveyVisible = computed(()=>activeChat.value.isSurveyVisible);
    async function onUpload() {
        if (uploader.value) uploader.value.click();
    }
    function focusInput() {
        if (footer.value) footer.value.focusInput();
    }
    async function created() {
        if (!props.hidden) await onOpenChat();
        if (mode.value === LiveChatMode.started) await liveChatStore.getChat();
    }
    onMounted(()=>{
        startUpdateInterval();
    });
    onBeforeUnmount(()=>{
        if (getInterval) clearInterval(getInterval);
    });
    function getInputFiles() {
        const files = [];
        if (uploader.value?.files && uploader.value.files.length > 0) for(let index = 0; index < uploader.value.files.length; index += 1){
            const file = uploader.value.files.item(index);
            if (file) files.push(file);
        }
        return files;
    }
    function startUpdateInterval() {
        if (getInterval) {
            Timer.clearInterval(getInterval);
            getInterval = 0;
        }
        getInterval = Timer.setTimeout(()=>{
            new Promise((resolve)=>{
                // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
                if (currentChat.value.status < LiveChatStatus.ENDED) liveChatStore.getChat().then(()=>resolve()).catch(()=>{
                    resolve();
                });
                else resolve();
            }).then(startUpdateInterval).catch(()=>true);
        }, 1000);
    }
    async function onOpenChat() {
        await liveChatStore.openChat(true);
    }
    function showHideButton() {
        return mode.value === LiveChatMode.started && // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        activeChat.value.status !== LiveChatStatus.IN_CHAT && // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        activeChat.value.status !== LiveChatStatus.INCOMING;
    }
    function onHide() {
        if (showHideButton() || mode.value === LiveChatMode.preopened) onClose();
        else emitHide();
    }
    function onPopState(event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        window.history.forward();
        nextAnimationFrame().then(()=>{
            if (!props.hidden) {
                if (showImagesPreviewModal.value) {
                    hideImagesModal();
                    window.addEventListener('popstate', onPopState);
                } else onHide();
            }
        });
        window.removeEventListener('popstate', onPopState);
    }
    function hideImagesModal() {
        previewImages.value = [];
        previewImageTitle.value = '';
        currentPreviewImageIndex.value = 0;
        allowPreviewImagesDelete.value = false;
    }
    function onSlideChange(value) {
        currentPreviewImageIndex.value = value;
    }
    function onFilePreview(param) {
        let { url, type } = param;
        if (FileUtils.isImage(url)) {
            allowPreviewImagesDelete.value = false;
            previewImageTitle.value = type === LiveChatMessageType.client ? $translate('LIVECHAT_TEXT_YOU').value : $translate('LIVECHAT_TEXT_OPERATOR').value;
            previewImages.value = getConversationFiles(type);
            currentPreviewImageIndex.value = previewImages.value.findIndex((item)=>item.url === url);
        } else window.open(url, '_blank');
    }
    function onFooterFilePreview(id) {
        const file = tempFiles.value.find((item)=>item.id === id);
        if (file && !FileUtils.isImage(file.url)) window.open(file.url, '_blank');
        else {
            allowPreviewImagesDelete.value = true;
            previewImageTitle.value = $translate('LIVECHAT_TEXT_YOU').value;
            previewImages.value = [
                ...tempFiles.value
            ];
            currentPreviewImageIndex.value = tempFiles.value.findIndex((item)=>item.id === id);
        }
    }
    function getConversationFiles(type) {
        const files = [];
        for (const msg of activeChat.value.conversation)if (msg.messageType === type && msg.files.length > 0) files.push(...msg.files.map((file)=>({
                ...file,
                id: 0
            })));
        return files;
    }
    async function onSend(msg) {
        if (sendRequestPerforming) return;
        let response = true;
        sendRequestPerforming = true;
        try {
            message.value = '';
            await nextTick();
            focusInput();
            if (mode.value === LiveChatMode.opened) response = await liveChatStore.startChat({
                question: msg,
                betId: props.betId
            });
            else // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            if (currentChat.value.status === LiveChatStatus.INCOMING || // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            currentChat.value.status === LiveChatStatus.IN_CHAT) response = await liveChatStore.message(msg);
            if (response) await liveChatStore.getChat();
        } catch  {
            message.value = msg;
        } finally{
            sendRequestPerforming = false;
            await nextTick();
            focusInput();
            await nextTick();
            body.value?.scrollDown();
        }
    }
    function onClose() {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        if (currentChat.value.status === LiveChatStatus.INCOMING || currentChat.value.status === LiveChatStatus.IN_CHAT) showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                confirmMessage: $translate('WEB2_LIVECHAT_MODAL_CLOSE_DESCRIPTION').value,
                title: $translate('WEB2_LIVECHAT_MODAL_CLOSE_TITLE').value,
                buttons: [
                    {},
                    {
                        label: $translate('WEB2_MOBILE_CANCEL').value
                    }
                ],
                dataTestId: 'livechat-close'
            }
        }).subscribe({
            [DialogAction.CONFIRM]: ()=>doCloseChat()
        });
        else {
            emitHide();
            if (mode.value === LiveChatMode.started) liveChatStore.setMode(LiveChatMode.opened);
        }
        return true;
    }
    async function doCloseChat() {
        const { status } = currentChat.value;
        try {
            await liveChatStore.closeChat();
        } catch  {}
        //
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        if (1 === status) {
            emitHide();
            if (mode.value === LiveChatMode.started) liveChatStore.setMode(LiveChatMode.opened);
        }
        return true;
    }
    function onChangeMuted(isMuted) {
        liveChatStore.setMuted(isMuted);
    }
    async function onSurveySubmit(data) {
        surveyProcessing.value = true;
        try {
            const response = await liveChatStore.surveySubmit(data);
            if (response) liveChatStore.setMode(LiveChatMode.preopened);
        } catch  {
            liveChatStore.setMode(LiveChatMode.preopened);
        } finally{
            surveyProcessing.value = false;
        }
    }
    async function onUploadEvent(files) {
        await liveChatStore.upload(files);
    }
    async function onFileRemove(id) {
        if (showImagesPreviewModal.value) hideImagesModal();
        await liveChatStore.removeFileCallback(id);
    }
    function openFeedback() {
        onClose();
        router.push({
            name: RouteName.FEEDBACK
        });
    }
    function onCallback() {
        onClose();
        router.push({
            name: RouteName.CALLBACK
        });
    }
    function onOpenFaq() {
        emitHide();
        router.push({
            name: RouteName.FAQ
        });
    }
    async function doAction(payload) {
        await liveChatStore.doAction(payload);
    }
    async function emitUpload() {
        const files = getInputFiles();
        if (uploader.value) uploader.value.value = '';
        await onUploadEvent(files);
    }
    function onMessageInput(msg) {
        message.value = msg;
        if (msg) liveChatStore.setUserTypingDate(Date.now());
    }
    function emitHide() {
        emits('hide');
    }
    function emitDragStart(event) {
        emits('drag-start', event);
    }
    watch(()=>props.hidden, async (newValue)=>{
        if (mode.value === LiveChatMode.opened || mode.value === LiveChatMode.busy) await onOpenChat();
        liveChatStore.setHiddenCallback(newValue);
    });
    watch(message, ()=>{
        if (message.value) liveChatStore.setUserTypingDate(Date.now());
    });
    watch(()=>props.defaultSubject, (newValue)=>{
        if (mode.value !== LiveChatMode.started) message.value = newValue;
    }, {
        immediate: true
    });
    watch(()=>mode.value, (newValue)=>{
        if (newValue === LiveChatMode.started) message.value = '';
    }, {
        immediate: true
    });
    return {
        created,
        /** header * */ chatMode,
        activeChat,
        muted,
        loaded,
        chatStatus,
        onChangeMuted,
        onHide,
        emitDragStart,
        /** body * */ body,
        surveyProcessing,
        isCallbackEnabled,
        startMessage,
        isSurveyVisible,
        onSurveySubmit,
        onClose,
        onFilePreview,
        openFeedback,
        onOpenFaq,
        onCallback,
        doAction,
        /** footer * */ showFooter,
        footer,
        message,
        isDisabledInput,
        chatActive,
        tempFiles,
        staffTyping,
        loadingFilesCounter,
        onSend,
        onUpload,
        onFileRemove,
        onFooterFilePreview,
        onMessageInput,
        onOpenChat,
        /** input * */ uploader,
        emitUpload,
        /** images preview * */ showImagesPreviewModal,
        currentPreviewImageIndex,
        previewImageTitle,
        previewImages,
        allowPreviewImagesDelete,
        onSlideChange,
        hideImagesModal
    };
}
