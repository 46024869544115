import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { LiveChatMessageType } from 'web/src/modules/live-chat/services/api/enums';
import LiveChatMessageTime from 'web/src/modules/live-chat/components/LiveChatMessageTime/LiveChatMessageTime.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import { useLiveChatMessage } from 'web/src/modules/live-chat/components/LiveChatMessage/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LiveChatMessage',
    props: {
        type: {
            default: LiveChatMessageType.staff
        },
        time: {
            default: ''
        },
        text: {
            default: ''
        },
        showSystemIcon: {
            type: Boolean,
            default: true
        }
    },
    emits: [
        "click",
        "open-faq"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { spanTag, isBot, formattedText, isSystem, emitClick, emitOpenFaq } = useLiveChatMessage(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['message']]: true,
                    [_ctx.$style[`message--${props.type}`]]: true
                }),
                onClick: _cache[0] || (_cache[0] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(emitClick) && _unref(emitClick)(...args);
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['message__text']]: !_unref(isSystem),
                        [_ctx.$style['message__text--system']]: _unref(isSystem)
                    })
                }, [
                    _unref(isBot) ? (_openBlock(), _createBlock(_unref(VIcon), {
                        key: 0,
                        name: _unref(IconName).BOT,
                        size: _unref(IconSize).SIZE_20,
                        class: _normalizeClass(_ctx.$style['message__info'])
                    }, null, 8, [
                        "name",
                        "size",
                        "class"
                    ])) : _createCommentVNode("", true),
                    _createVNode(VDynamicContent, {
                        content: _unref(formattedText),
                        tag: _unref(spanTag),
                        onOpenFaq: _unref(emitOpenFaq)
                    }, null, 8, [
                        "content",
                        "tag",
                        "onOpenFaq"
                    ])
                ], 2),
                _unref(isSystem) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(LiveChatMessageTime, {
                    key: 0,
                    time: _ctx.time,
                    type: _ctx.type
                }, null, 8, [
                    "time",
                    "type"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'LiveChatMessage'
                ]
            ]);
        };
    }
});
