export default function linkify(text) {
    const replacePattern1 = /(\b(https?|ftp|leonapp|otpauth):\/\/[\w!#%&+,-./:;=?@|~]*[\w#%&+-/=@|~])/gim;
    let replacedText = text.replace(replacePattern1, (url)=>{
        const temporaryUrl = url.replace('file://', 'https://');
        return `<a href="${temporaryUrl}" target="_blank">${temporaryUrl}</a>`;
    });
    // Change email addresses to mailto:: links
    const replacePattern2 = /([a-zA-Z0-9._%+-]+@[_a-z]+?\.[a-z]{2,6})/gim;
    replacedText = replacedText.replace(replacePattern2, '<a href="mailto:$1">$1</a>');
    return replacedText;
}
