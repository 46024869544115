import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { LiveChatMode } from 'web/src/modules/live-chat/enums';
import { getStyleNumberValue } from 'web/src/components/Input/utils';
import { getIsSendButtonDisabled } from 'web/src/modules/live-chat/components/LiveChatFooter/composables/utils';
export default function useLiveChatFooter(props, emits) {
    const input = ref();
    const filesScroll = ref();
    const inputRows = ref(1);
    const isFocused = ref(false);
    const filesLength = computed(()=>props.files.length + props.loadingFilesCount);
    const innerValue = computed(()=>props.value);
    // eslint-disable-next-line max-len
    const showUploadButton = computed(()=>(props.mode === LiveChatMode.started || props.mode === LiveChatMode.opened) && props.chatActive);
    // eslint-disable-next-line max-len
    const showUploadedFiles = computed(()=>showUploadButton.value && (props.files.length > 0 || props.loadingFilesCount > 0));
    const isFirstMessage = computed(()=>props.mode === LiveChatMode.opened);
    const maxLength = computed(()=>isFirstMessage.value ? 3000 : 0);
    const isNewChatFooter = computed(()=>props.mode === LiveChatMode.preopened);
    const isSendButtonDisabled = computed(()=>getIsSendButtonDisabled(props, innerValue.value));
    const uploadButton = {
        iconName: IconName.CLIP,
        iconSize: IconSize.SIZE_24,
        kind: ButtonKind.TRANSPARENT
    };
    const sendButton = {
        iconName: IconName.PAPERPLANE,
        iconSize: IconSize.SIZE_24,
        kind: ButtonKind.TRANSPARENT_BLUE
    };
    const newChatButton = {
        kind: ButtonKind.PRIMARY,
        fullWidth: true
    };
    const saveChatButton = {
        kind: ButtonKind.TRANSPARENT,
        fullWidth: true
    };
    const addMoreIcon = {
        name: IconName.ADD,
        size: IconSize.SIZE_28
    };
    function setInnerValue(value) {
        emitInput(value);
    }
    function emitInput(value) {
        emits('input', value);
    }
    function emitSend() {
        emits('send', innerValue.value.trim());
    }
    function sendOnEnter() {
        "1";
        send();
    }
    function emitHide() {
        emits('hide');
    }
    function send() {
        if (!isSendButtonDisabled.value) emitSend();
    }
    function onUpload() {
        if (showUploadButton.value) emitUpload();
    }
    function emitUpload() {
        emits('upload');
    }
    function emitStartNewChat() {
        emits('start-new-chat');
    }
    function emitRemoveFile(id) {
        emits('remove-file', id);
    }
    onMounted(async ()=>{
        await nextTick();
        focusInput();
        recalculateInputRows();
    });
    function onFocus() {
        isFocused.value = true;
    }
    function onBlur() {
        isFocused.value = false;
    }
    function onInput(event) {
        const { target } = event;
        HTMLTextAreaElement;
        setInnerValue(target.value);
    }
    function emitPreviewFile(id) {
        emits('preview-file', id);
    }
    function focusInput() {
        if (input.value) input.value.focus();
    }
    function recalculateInputRows() {
        if (!input.value) return;
        const computedStyle = window.getComputedStyle(input.value);
        const padding = getStyleNumberValue(computedStyle, 'paddingBottom') + getStyleNumberValue(computedStyle, 'paddingTop');
        const lineHeight = getStyleNumberValue(computedStyle, 'lineHeight');
        input.value.style.height = '1px';
        const rows = innerValue.value ? Math.floor((input.value.scrollHeight - padding) / lineHeight) : 1;
        input.value.style.height = 'auto';
        if (rows <= 1) {
            inputRows.value = 1;
            return;
        }
        inputRows.value = rows > 15 ? 15 : rows;
    }
    watch(filesLength, async (newValue, oldValue)=>{
        if (newValue > oldValue) {
            await nextTick();
            const element = filesScroll.value;
            if (element) element.scrollRight?.();
        }
    });
    watch(innerValue, ()=>{
        recalculateInputRows();
    }, {
        immediate: true
    });
    return {
        filesScroll,
        input,
        innerValue,
        maxLength,
        inputRows,
        addMoreIcon,
        isFocused,
        isSendButtonDisabled,
        showUploadedFiles,
        showUploadButton,
        isNewChatFooter,
        newChatButton,
        uploadButton,
        sendButton,
        onUpload,
        onInput,
        onFocus,
        onBlur,
        sendOnEnter,
        saveChatButton,
        emitStartNewChat,
        emitHide,
        emitPreviewFile,
        emitRemoveFile,
        emitUpload,
        send,
        focusInput
    };
}
