import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import LiveChatAction from 'web/src/modules/live-chat/components/LiveChatAction/LiveChatAction.vue';
import LiveChatMessage from 'web/src/modules/live-chat/components/LiveChatMessage/LiveChatMessage.vue';
import LiveChatFilesMessage from 'web/src/modules/live-chat/components/LiveChatFilesMessages/LiveChatFilesMessage.vue';
import { useLiveChatConversation } from 'web/src/modules/live-chat/components/LiveChatConversation/composables';
import { LiveChatMessageType } from 'web/src/modules/live-chat/services/api/enums';
import LiveChatSurveyForm from 'web/src/modules/live-chat/components/LiveChatSurveyForm/LiveChatSurveyForm.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LiveChatConversation',
    props: {
        chat: {},
        mode: {},
        hidden: {
            type: Boolean
        },
        isSurveyVisible: {
            type: Boolean
        },
        surveyProcessing: {
            type: Boolean
        },
        startMessage: {}
    },
    emits: [
        "survey-submit",
        "survey-submit-full",
        "preview-file",
        "open-faq",
        "scroll-down",
        "do-action"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { time, endTime, isChatStarted, computedActions, isSurveyFormAvailable, emitOpenFaq, getMessageTime, emitPreviewFile, emitDoAction, onSurveyRatingClick, onSurveyFullClick } = useLiveChatConversation(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['conversation'])
            }, [
                _ctx.chat.isCreatedByStaff ? _createCommentVNode("", true) : (_openBlock(), _createBlock(LiveChatMessage, {
                    key: 0,
                    time: _unref(time),
                    type: _unref(LiveChatMessageType).bot,
                    text: _ctx.startMessage,
                    onOpenFaq: _unref(emitOpenFaq)
                }, null, 8, [
                    "time",
                    "type",
                    "text",
                    "onOpenFaq"
                ])),
                _unref(isChatStarted) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _ctx.chat.question ? (_openBlock(), _createBlock(LiveChatMessage, {
                        key: 0,
                        time: _unref(time),
                        type: _unref(LiveChatMessageType).client,
                        text: _ctx.chat.question
                    }, null, 8, [
                        "time",
                        "type",
                        "text"
                    ])) : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chat.conversation, (message, index)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                            message.files.length ? (_openBlock(), _createBlock(LiveChatFilesMessage, {
                                key: `f${index}`,
                                type: message.messageType,
                                files: message.files,
                                time: _unref(getMessageTime)(message),
                                onPreviewFile: ($event)=>_unref(emitPreviewFile)($event, message.messageType)
                            }, null, 8, [
                                "type",
                                "files",
                                "time",
                                "onPreviewFile"
                            ])) : _createCommentVNode("", true),
                            message.message ? (_openBlock(), _createBlock(LiveChatMessage, {
                                key: `m${index}`,
                                text: message.message,
                                type: message.messageType,
                                time: _unref(getMessageTime)(message)
                            }, null, 8, [
                                "text",
                                "type",
                                "time"
                            ])) : _createCommentVNode("", true)
                        ], 64))), 256)),
                    _unref(isSurveyFormAvailable) ? (_openBlock(), _createBlock(LiveChatSurveyForm, {
                        key: 1,
                        processing: _ctx.surveyProcessing,
                        time: _unref(endTime),
                        "survey-message": _ctx.chat.surveyMessage,
                        "survey-rating": _ctx.chat.surveyRating,
                        type: _unref(LiveChatMessageType).staff,
                        onSurveySubmit: _unref(onSurveyRatingClick),
                        onSurveySubmitFull: _unref(onSurveyFullClick)
                    }, null, 8, [
                        "processing",
                        "time",
                        "survey-message",
                        "survey-rating",
                        "type",
                        "onSurveySubmit",
                        "onSurveySubmitFull"
                    ])) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['conversation__actions'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(computedActions), (action, index)=>(_openBlock(), _createBlock(LiveChatAction, {
                                key: index,
                                action: action,
                                class: _normalizeClass(_ctx.$style['conversation__action']),
                                onClick: _unref(emitDoAction)
                            }, null, 8, [
                                "action",
                                "class",
                                "onClick"
                            ]))), 128))
                    ], 2)
                ], 64)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LiveChatConversation'
                ]
            ]);
        };
    }
});
