import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import LiveChatFooter from 'web/src/modules/live-chat/components/LiveChatFooter/LiveChatFooter.vue';
import LiveChatBody from 'web/src/modules/live-chat/components/LiveChatBody/LiveChatBody.vue';
import LiveChatHeader from 'web/src/modules/live-chat/components/LiveChatHeader/LiveChatHeader.vue';
import LiveChatImagesPreview from 'web/src/modules/live-chat/components/LiveChatImagesPreview/LiveChatImagesPreview.vue';
import { useLiveChat } from 'web/src/modules/live-chat/components/LiveChat/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LiveChat',
    props: {
        hidden: {
            type: Boolean,
            default: true
        },
        defaultSubject: {
            default: ''
        },
        betId: {
            default: ''
        }
    },
    emits: [
        "drag-start",
        "hide"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { created, chatMode, activeChat, chatStatus, muted, loaded, onChangeMuted, onHide, emitDragStart, body, surveyProcessing, isCallbackEnabled, startMessage, isSurveyVisible, onSurveySubmit, onClose, onFilePreview, openFeedback, onOpenFaq, onCallback, doAction, showFooter, footer, message, isDisabledInput, chatActive, tempFiles, staffTyping, loadingFilesCounter, onSend, onUpload, onFileRemove, onFooterFilePreview, onMessageInput, onOpenChat, uploader, emitUpload, showImagesPreviewModal, currentPreviewImageIndex, previewImageTitle, previewImages, allowPreviewImagesDelete, onSlideChange, hideImagesModal } = useLiveChat(props, emits);
        created();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                id: "live-chat",
                class: _normalizeClass({
                    [_ctx.$style['live-chat']]: true
                })
            }, [
                _createVNode(LiveChatHeader, {
                    mode: _unref(chatMode),
                    "chat-status": _unref(chatStatus),
                    muted: _unref(muted),
                    loaded: _unref(loaded),
                    onChangeMuted: _unref(onChangeMuted),
                    onPrefixClick: _unref(onHide),
                    onDragStart: _unref(emitDragStart)
                }, null, 8, [
                    "mode",
                    "chat-status",
                    "muted",
                    "loaded",
                    "onChangeMuted",
                    "onPrefixClick",
                    "onDragStart"
                ]),
                _createVNode(LiveChatBody, {
                    ref_key: "body",
                    ref: body,
                    loaded: _unref(loaded),
                    mode: _unref(chatMode),
                    chat: _unref(activeChat),
                    "survey-processing": _unref(surveyProcessing),
                    hidden: _ctx.hidden,
                    "is-callback-enabled": _unref(isCallbackEnabled),
                    "start-message": _unref(startMessage),
                    "is-survey-visible": _unref(isSurveyVisible),
                    class: _normalizeClass(_ctx.$style['live-chat__body']),
                    onSurveySubmit: _unref(onSurveySubmit),
                    onSurveySubmitFull: _unref(onSurveySubmit),
                    onClose: _unref(onClose),
                    onPreviewFile: _unref(onFilePreview),
                    onFeedback: _unref(openFeedback),
                    onOpenFaq: _unref(onOpenFaq),
                    onCallback: _unref(onCallback),
                    onDoAction: _unref(doAction)
                }, null, 8, [
                    "loaded",
                    "mode",
                    "chat",
                    "survey-processing",
                    "hidden",
                    "is-callback-enabled",
                    "start-message",
                    "is-survey-visible",
                    "class",
                    "onSurveySubmit",
                    "onSurveySubmitFull",
                    "onClose",
                    "onPreviewFile",
                    "onFeedback",
                    "onOpenFaq",
                    "onCallback",
                    "onDoAction"
                ]),
                _unref(showFooter) ? (_openBlock(), _createBlock(LiveChatFooter, {
                    key: 0,
                    ref_key: "footer",
                    ref: footer,
                    value: _unref(message),
                    mode: _unref(chatMode),
                    disabled: _unref(isDisabledInput),
                    "chat-active": _unref(chatActive),
                    files: _unref(tempFiles),
                    "is-staff-typing": _unref(staffTyping),
                    "loading-files-count": _unref(loadingFilesCounter),
                    onSend: _unref(onSend),
                    onUpload: _unref(onUpload),
                    onRemoveFile: _unref(onFileRemove),
                    onPreviewFile: _unref(onFooterFilePreview),
                    onInput: _unref(onMessageInput),
                    onStartNewChat: _unref(onOpenChat),
                    onHide: _unref(onHide)
                }, null, 8, [
                    "value",
                    "mode",
                    "disabled",
                    "chat-active",
                    "files",
                    "is-staff-typing",
                    "loading-files-count",
                    "onSend",
                    "onUpload",
                    "onRemoveFile",
                    "onPreviewFile",
                    "onInput",
                    "onStartNewChat",
                    "onHide"
                ])) : _createCommentVNode("", true),
                _createElementVNode("input", {
                    ref_key: "uploader",
                    ref: uploader,
                    type: "file",
                    accept: "image/gif,image/jpeg,image/pjpeg,image/png,application/pdf",
                    multiple: "",
                    style: {
                        display: "none"
                    },
                    onChange: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(emitUpload) && _unref(emitUpload)(...args);
                    })
                }, null, 544),
                _unref(showImagesPreviewModal) ? (_openBlock(), _createBlock(LiveChatImagesPreview, {
                    key: 1,
                    "active-index": _unref(currentPreviewImageIndex),
                    title: _unref(previewImageTitle),
                    images: _unref(previewImages),
                    "allow-delete": _unref(allowPreviewImagesDelete),
                    onSlideChange: _unref(onSlideChange),
                    onHide: _unref(hideImagesModal),
                    onRemove: _unref(onFileRemove),
                    onDragStart: _unref(emitDragStart)
                }, null, 8, [
                    "active-index",
                    "title",
                    "images",
                    "allow-delete",
                    "onSlideChange",
                    "onHide",
                    "onRemove",
                    "onDragStart"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LiveChat'
                ]
            ]);
        };
    }
});
