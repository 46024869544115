import { computed, nextTick, onMounted, watch } from 'vue';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
import { LiveChatMode, LiveChatStatus } from 'web/src/modules/live-chat/enums';
export default function useLiveChatConversation(props, emits) {
    const currentDate = Date.now();
    const isChatStarted = computed(()=>props.mode === LiveChatMode.started || props.mode === LiveChatMode.preopened);
    const isChatEnded = computed(()=>props.chat.status >= LiveChatStatus.ENDED);
    const endTime = computed(()=>DateTime.formatTimeStamp(Date.now(), TimeFormats.hoursMinutes));
    const computedActions = computed(()=>props.chat.actions);
    const time = computed(()=>{
        const date = isChatStarted.value ? props.chat.started : currentDate;
        return DateTime.formatTimeStamp(date, TimeFormats.hoursMinutes);
    });
    const isSurveyFormAvailable = computed(()=>!!(isChatEnded.value && props.isSurveyVisible));
    onMounted(async ()=>{
        await nextTick();
        emitScrollDown();
    });
    function getMessageTime(message) {
        return DateTime.formatTimeStamp(message.date, TimeFormats.hoursMinutes);
    }
    function onSurveyRatingClick(data) {
        emits('survey-submit', data);
    }
    function onSurveyFullClick(data) {
        emits('survey-submit-full', data);
    }
    function emitPreviewFile(url, type) {
        emits('preview-file', {
            url,
            type
        });
    }
    function emitOpenFaq() {
        emits('open-faq');
    }
    function emitScrollDown() {
        emits('scroll-down');
    }
    function emitDoAction(value) {
        emits('do-action', value);
    }
    watch(()=>props.hidden, async (newValue)=>{
        if (!newValue) {
            await nextTick();
            emitScrollDown();
        }
    }, {
        immediate: true
    });
    watch(()=>props.chat, (to, from)=>{
        if (from && to && (to.conversation.length > from.conversation.length || to.actions.length > from.actions.length || to.status === LiveChatStatus.ENDED)) emitScrollDown();
    }, {
        deep: true,
        immediate: true
    });
    return {
        time,
        endTime,
        isChatStarted,
        computedActions,
        isSurveyFormAvailable,
        emitOpenFaq,
        getMessageTime,
        emitPreviewFile,
        emitDoAction,
        onSurveyRatingClick,
        onSurveyFullClick
    };
}
