import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { VScrollbar } from '@components/v-scrollbar';
import LiveChatConversation from 'web/src/modules/live-chat/components/LiveChatConversation/LiveChatConversation.vue';
import LiveChatBusyForm from 'web/src/modules/live-chat/components/LiveChatBusyForm/LiveChatBusyForm.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { useLiveChatBody } from 'web/src/modules/live-chat/components/LiveChatBody/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LiveChatBody',
    props: {
        mode: {},
        loaded: {
            type: Boolean
        },
        chat: {},
        startMessage: {},
        surveyProcessing: {
            type: Boolean
        },
        hidden: {
            type: Boolean
        },
        isCallbackEnabled: {
            type: Boolean
        },
        isSurveyVisible: {
            type: Boolean
        }
    },
    emits: [
        "close",
        "feedback",
        "open-faq",
        "callback",
        "do-action",
        "survey-submit",
        "survey-submit-full",
        "preview-file"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { scrollbar, isBusyMode, isConversationMode, scrollDown, emitFeedback, emitCallback, emitPreviewFile, emitSurveyRatingSubmit, emitSurveyFullSubmit, emitOpenFaq, emitDoAction } = useLiveChatBody(props, emits);
        __expose({
            scrollDown
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['live-chat-body'])
            }, [
                _createVNode(_unref(VScrollbar), {
                    ref_key: "scrollbar",
                    ref: scrollbar,
                    class: _normalizeClass({
                        [_ctx.$style['live-chat-body__inner']]: true,
                        [_ctx.$style['live-chat-body__inner--bottom']]: _ctx.loaded && _unref(isConversationMode)
                    }),
                    "handle-resize-change": ""
                }, {
                    default: _withCtx(()=>[
                            _createElementVNode("div", null, [
                                _ctx.loaded ? (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 1
                                }, [
                                    _unref(isBusyMode) ? (_openBlock(), _createBlock(LiveChatBusyForm, {
                                        key: 0,
                                        "is-callback-enabled": _ctx.isCallbackEnabled,
                                        onFeedback: _unref(emitFeedback),
                                        onCallback: _unref(emitCallback)
                                    }, null, 8, [
                                        "is-callback-enabled",
                                        "onFeedback",
                                        "onCallback"
                                    ])) : _createCommentVNode("", true),
                                    _unref(isConversationMode) ? (_openBlock(), _createBlock(LiveChatConversation, {
                                        key: 1,
                                        ref: "conversation",
                                        chat: _ctx.chat,
                                        mode: _ctx.mode,
                                        hidden: _ctx.hidden,
                                        "start-message": _ctx.startMessage,
                                        "is-survey-visible": _ctx.isSurveyVisible,
                                        onPreviewFile: _unref(emitPreviewFile),
                                        onSurveySubmit: _unref(emitSurveyRatingSubmit),
                                        onSurveySubmitFull: _unref(emitSurveyFullSubmit),
                                        onOpenFaq: _unref(emitOpenFaq),
                                        onScrollDown: _unref(scrollDown),
                                        onDoAction: _unref(emitDoAction)
                                    }, null, 8, [
                                        "chat",
                                        "mode",
                                        "hidden",
                                        "start-message",
                                        "is-survey-visible",
                                        "onPreviewFile",
                                        "onSurveySubmit",
                                        "onSurveySubmitFull",
                                        "onOpenFaq",
                                        "onScrollDown",
                                        "onDoAction"
                                    ])) : _createCommentVNode("", true)
                                ], 64)) : (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['live-chat-body__loading'])
                                }, [
                                    _createVNode(_unref(VLoaderDelayed))
                                ], 2))
                            ])
                        ]),
                    _: 1
                }, 8, [
                    "class"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'LiveChatBody'
                ]
            ]);
        };
    }
});
