import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx, withModifiers as _withModifiers, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "value",
    "maxlength",
    "rows",
    "placeholder",
    "disabled"
];
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { VDottedLoader } from '@components/loader';
import { VScrollbar } from '@components/v-scrollbar';
import { VLoaderDelayed } from 'web/src/components/Loader';
import LiveChatFilePreview from 'web/src/modules/live-chat/components/LiveChatFilePreview/LiveChatFilePreview.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useLiveChatFooter } from 'web/src/modules/live-chat/components/LiveChatFooter/composables';
import { LiveChatMode } from 'web/src/modules/live-chat/enums';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LiveChatFooter',
    props: {
        mode: {
            default: LiveChatMode.busy
        },
        chatActive: {
            type: Boolean
        },
        isInputDisabled: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        value: {
            default: ''
        },
        files: {
            default: ()=>[]
        },
        loadingFilesCount: {
            default: 0
        },
        isStaffTyping: {
            type: Boolean
        }
    },
    emits: [
        "send",
        "input",
        "hide",
        "upload",
        "start-new-chat",
        "remove-file",
        "preview-file"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { filesScroll, input, innerValue, maxLength, inputRows, addMoreIcon, isFocused, isSendButtonDisabled, showUploadedFiles, showUploadButton, isNewChatFooter, newChatButton, uploadButton, sendButton, onUpload, onInput, onFocus, onBlur, sendOnEnter, saveChatButton, emitStartNewChat, emitHide, emitPreviewFile, emitRemoveFile, emitUpload, send, focusInput } = useLiveChatFooter(props, emits);
        __expose({
            focusInput
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['live-chat-footer'])
            }, [
                _unref(isNewChatFooter) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 0
                }, [
                    _createVNode(VButton, _mergeProps(_unref(newChatButton), {
                        label: _ctx.$t('WEB2_LIVECHAT_START_NEW_BTN'),
                        class: _ctx.$style['live-chat-footer__new'],
                        onClick: _unref(emitStartNewChat)
                    }), null, 16, [
                        "label",
                        "class",
                        "onClick"
                    ]),
                    _createVNode(VButton, _mergeProps(_unref(saveChatButton), {
                        label: _ctx.$t('LIVECHAT_SURVEY_CLOSE_BTN'),
                        onClick: _unref(emitHide)
                    }), null, 16, [
                        "label",
                        "onClick"
                    ])
                ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _ctx.isStaffTyping ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['live-chat-footer__typing'])
                    }, [
                        _createTextVNode(_toDisplayString(_ctx.$t('LIVECHAT_OPERATOR_TYPING')) + " ", 1),
                        _createVNode(_unref(VDottedLoader))
                    ], 2)) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['live-chat-footer__input-wrapper']]: true,
                            [_ctx.$style['live-chat-footer__input-wrapper-focus']]: _unref(isFocused),
                            [_ctx.$style['live-chat-footer__input-wrapper--disabled']]: !!_ctx.disabled
                        })
                    }, [
                        _unref(showUploadedFiles) ? (_openBlock(), _createBlock(_unref(VScrollbar), {
                            key: 0,
                            ref_key: "filesScroll",
                            ref: filesScroll,
                            class: _normalizeClass(_ctx.$style['live-chat-footer__files-scroll'])
                        }, {
                            default: _withCtx(()=>[
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['live-chat-footer__files'])
                                    }, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loadingFilesCount, (counter)=>(_openBlock(), _createElementBlock("div", {
                                                key: counter,
                                                class: _normalizeClass({
                                                    [_ctx.$style['live-chat-footer__file-more']]: true,
                                                    [_ctx.$style['live-chat-footer__file-more--loading']]: true
                                                })
                                            }, [
                                                _createVNode(_unref(VLoaderDelayed), {
                                                    class: _normalizeClass(_ctx.$style['live-chat-footer__file-more-loader'])
                                                }, null, 8, [
                                                    "class"
                                                ]),
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['live-chat-footer__file-more-name'])
                                                }, _toDisplayString(_ctx.$t('WEB2_CHAT_FILE_UPLOADING')), 3)
                                            ], 2))), 128)),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file)=>(_openBlock(), _createBlock(LiveChatFilePreview, {
                                                key: file.id,
                                                file: file,
                                                "is-footer": "",
                                                "is-delete-allowed": "",
                                                class: _normalizeClass(_ctx.$style['live-chat-footer__file']),
                                                onDelete: ($event)=>_unref(emitRemoveFile)(file.id),
                                                onClick: ($event)=>_unref(emitPreviewFile)(file.id)
                                            }, null, 8, [
                                                "file",
                                                "class",
                                                "onDelete",
                                                "onClick"
                                            ]))), 128)),
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['live-chat-footer__file-more']),
                                            onClick: _cache[0] || (_cache[0] = //@ts-ignore
                                            function() {
                                                for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                    args[_key] = arguments[_key];
                                                }
                                                return _unref(emitUpload) && _unref(emitUpload)(...args);
                                            })
                                        }, [
                                            _createVNode(_unref(VIcon), _mergeProps(_unref(addMoreIcon), {
                                                class: _ctx.$style['live-chat-footer__file-more-icon']
                                            }), null, 16, [
                                                "class"
                                            ]),
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['live-chat-footer__file-more-name'])
                                            }, _toDisplayString(_ctx.$t('WEB2_LIVECHAT_ADD_MORE')), 3)
                                        ], 2)
                                    ], 2)
                                ]),
                            _: 1
                        }, 8, [
                            "class"
                        ])) : _createCommentVNode("", true),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['live-chat-footer__input-container'])
                        }, [
                            _unref(showUploadButton) ? (_openBlock(), _createBlock(VButton, _mergeProps({
                                key: 0
                            }, _unref(uploadButton), {
                                class: _ctx.$style['live-chat-footer__button'],
                                "data-test-id": "chat-upload-button",
                                onClick: _unref(onUpload)
                            }), null, 16, [
                                "class",
                                "onClick"
                            ])) : _createCommentVNode("", true),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['live-chat-footer__input-content'])
                            }, [
                                _createElementVNode("textarea", {
                                    ref_key: "input",
                                    ref: input,
                                    value: _unref(innerValue),
                                    class: _normalizeClass(_ctx.$style['live-chat-footer__input']),
                                    maxlength: _unref(maxLength) || '',
                                    rows: _unref(inputRows),
                                    placeholder: _ctx.$t('LIVECHAT_INPUT_PLACEHOLDER'),
                                    disabled: _ctx.disabled,
                                    onInput: _cache[1] || (_cache[1] = //@ts-ignore
                                    function() {
                                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                            args[_key] = arguments[_key];
                                        }
                                        return _unref(onInput) && _unref(onInput)(...args);
                                    }),
                                    onFocus: _cache[2] || (_cache[2] = //@ts-ignore
                                    function() {
                                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                            args[_key] = arguments[_key];
                                        }
                                        return _unref(onFocus) && _unref(onFocus)(...args);
                                    }),
                                    onBlur: _cache[3] || (_cache[3] = //@ts-ignore
                                    function() {
                                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                            args[_key] = arguments[_key];
                                        }
                                        return _unref(onBlur) && _unref(onBlur)(...args);
                                    }),
                                    onKeydown: _cache[4] || (_cache[4] = _withKeys(_withModifiers(//@ts-ignore
                                    function() {
                                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                            args[_key] = arguments[_key];
                                        }
                                        return _unref(sendOnEnter) && _unref(sendOnEnter)(...args);
                                    }, [
                                        "exact",
                                        "prevent"
                                    ]), [
                                        "enter"
                                    ]))
                                }, null, 42, _hoisted_1)
                            ], 2),
                            (_openBlock(), _createBlock(VButton, _mergeProps({
                                key: 2
                            }, _unref(sendButton), {
                                disabled: _unref(isSendButtonDisabled),
                                class: _ctx.$style['live-chat-footer__button'],
                                "data-test-id": "chat-send-button",
                                onClick: _unref(send)
                            }), null, 16, [
                                "disabled",
                                "class",
                                "onClick"
                            ]))
                        ], 2)
                    ], 2)
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'LiveChatFooter'
                ]
            ]);
        };
    }
});
